import React from "react";
import styles from "./gallery-video-player.module.scss";
import VideoPlayer from "components/custom-video-player/VideoPlayer";

const GalleryVideoPlayer = ({ video, data }) => {
  const { thumbnail, approval, id, name } = data || {};

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.videoPreviewer}`}
      >
        <div className={styles.videoWrapper}>
          <VideoPlayer
            video={video}
            thumbnail={thumbnail}
            approval={approval}
            id={id}
            name={name}
            // outerComment
          />
        </div>
      </div>
    </>
  );
};

export default GalleryVideoPlayer;

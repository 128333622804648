import { Box, Stack } from "@mui/material";
import { ConfirmDialog, Snackbar } from "components";
import { useContext, useEffect, useState } from "react";
import ButtonBox from "../ButtonBox";
import DownloadPopover from "../DownloadPopover/DownloadPopover";
import { ReactComponent as DownloadsIcon } from "assets/images/Downloads.svg";
import { ReactComponent as DeleteIcon } from "assets/images/delete.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import RBGTable from "components/table/RBGTable";
import RBGPagination from "components/pagination/RBGPagination";
import moment from "moment";
import { getDataFromLocalStorage } from "utils/videoUtils";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";

const AllVideo = ({ getData, data, getAllVideo }) => {
  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const [openDialog, setOpenDialog] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const handleCloseDialog = () => {
    setOpenDialog({
      open: false,
      message: "",
      type: "",
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (val) => {
    setSelected(val);
  };

  const downloadSelectedVideos = async () => {
    const filteredArr = rows?.filter((obj) => selected?.includes(obj?.id));
    let video_group = JSON.parse(localStorage.getItem("video_group")) || {};
    let newIndex = new Date().toISOString();
    const newGroupData = filteredArr?.map((item) => {
      item.group_id = newIndex;
      return item;
    });
    video_group[newIndex] = newGroupData;
    localStorage.setItem("video_group", JSON.stringify(video_group));

    //pussing the videos into video_list
    let video_list = getDataFromLocalStorage("video_list");
    if (video_list) {
      newGroupData?.forEach((item) => {
        video_list?.push(item);
      });
      localStorage.setItem("video_list", JSON.stringify(video_list));
    } else {
      localStorage.setItem("video_list", JSON.stringify(newGroupData));
    }

    setSelected([]);
    setOpenDialog({ message: "", open: false, type: "" });
  };

  const handleDownloadVideo = () => {
    downloadSelectedVideos();
  };

  const handleDeleteVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    handleCloseDialog();
    try {
      const res = await AuthServices.deleteVideo({ id: selected });
      // await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve();
      //   }, 3000);
      // });
      const data = {
        message: "Video successfully deleted",
        class: "success",
      };
      setSelected([]);
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
      storeHandler(actions.SHOW_LOADER, false);
      getAllVideo();
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
      const data = {
        message: err?.data?.message || "Something went worng!",
        class: "error",
      };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    }
  };

  const handlePage = (val) => {
    setPage(val);
  };

  useEffect(() => {
    const filter_data = getData(data, searchText);
    filter_data?.forEach((item) => {
      item.date = moment(item?.date).format("MM/DD/YYYY HH:mm:ss") || '-';
    });
    handlePage(0);
    setRows(filter_data);
  }, [searchText]);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <h3 className="txt-medium">All Storage Videos</h3>
        <Stack direction={"row"} gap={4}>
          {!!selected?.length && (
            <ButtonBox
              variant="contained"
              color="error"
              startIcon={<DownloadsIcon fill="white" />}
              onClick={() =>
                setOpenDialog({
                  open: true,
                  message: "Are you sure you want to Download?",
                  type: "download",
                })
              }
            >
              Download
            </ButtonBox>
          )}

          {!!selected?.length && (
            <ButtonBox
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() =>
                setOpenDialog({
                  open: true,
                  message: "Are you sure you want to Delete?",
                  type: "delete",
                })
              }
            >
              Delete
            </ButtonBox>
          )}

          {!selected?.length && (
            <ButtonBox
              aria-describedby={"download-popover"}
              variant="contained"
              color="inherit"
              startIcon={<FilterIcon fill="black" />}
              sx={{
                ":hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
              onClick={handleClick}
            >
              Filter
            </ButtonBox>
          )}
          <DownloadPopover
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchText={searchText}
            handleSearch={handleSearchChange}
            data={rows}
          />
        </Stack>
      </Stack>
      <Box>
        <RBGTable
          key={rows?.length}
          page={page}
          selected={selected}
          setSelected={handleSelected}
          rows={rows}
        />
        {rows?.length > 5 && (
          <RBGPagination
            page={page}
            setPage={handlePage}
            count={Math.ceil(rows?.length / 5)}
          />
        )}

        <ConfirmDialog
          onClose={handleCloseDialog}
          open={openDialog.open}
          message={openDialog.message}
          className={"download-conformbox"}
          onConfirm={
            openDialog.type === "download"
              ? handleDownloadVideo
              : handleDeleteVideo
          }
        />

        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={setShowSnackBar}
        />
      </Box>
    </>
  );
};

export default AllVideo;

import { useEffect, useContext, useState } from "react";
import styles from "./dashboard.module.scss";
import Detective from "assets/images/Detectived.svg";
import Videostats from "assets/images/Videstats.svg";
import Videocaptured from "assets/images/Video.svg";
import Line from "assets/images/Line.svg";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import moment from "moment";
import { Box } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";

const InfoCard = ({ children }) => {
  return <div className={`p-3 ${styles.infoCard}`}>{children}</div>;
};
const InfoContainer = ({ children }) => {
  return <div className={`p-3 ${styles.infoCardbox}`}>{children}</div>;
};

const getNumberFromString = (value) => {
  const number = Number(value);
  return isNaN(number) ? 0 : number;
};

const Dashboard = () => {
  const {
    dispatch,
    state: { isAdmin },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const [dashboardData, setDashboardData] = useState({});
  const { total, today } = dashboardData || {};

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const getDashbard = async () => {
    const nowDate = moment(new Date()).format("YYYY-MM-DD");
    const start_date = `${moment(`${nowDate} 00:00:00`, "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("ddd, DD MMM YYYY HH:mm:ss")} GMT`;
    const end_date = `${moment(`${nowDate} 23:59:59`, "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("ddd, DD MMM YYYY HH:mm:ss")} GMT`;

    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await AuthServices.getDashboardData({
        start_date,
        end_date,
      });
      setDashboardData(data);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  useEffect(() => {
    getDashbard();
  }, []);

  return (
    <>
      {!!Object.keys(dashboardData || {}).length && (
        <div
          className={`flex-grow-1 d-flex ${
            IS_ADMIN ? "align-items-center" : "pt-4"
          } ${styles.dashboard_root}`}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: isAdmin ? "50%" : "60%" }}>
              <div className="text-center">
                <div
                  style={{
                    fontSize: "5.2rem",
                    fontWeight: "bold",
                    margin: ".9rem 0 1.5rem 0",
                  }}
                >
                  TODAY
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "30px",
                  justifyContent: IS_ADMIN ? "center" : "space-between",
                }}
              >
                <InfoCard>
                  <div
                    style={{
                      height: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "2px",
                        textAlign: "center",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      Active <br /> Contractors
                    </p>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      {getNumberFromString(today?.active_contractors)}
                    </div>
                  </div>
                  <div className={styles.infoIcon}>
                    <img src={Detective} />
                  </div>
                </InfoCard>
                <InfoCard>
                  <div
                    style={{
                      height: "95%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "2px",
                        textAlign: "center",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      Video Stats
                    </p>
                    <div
                      style={{
                        textAlign: "center",
                        width: "min-content",
                        background: "#edf7ed",
                        color: "#7ec581",
                        fontSize: ".6rem",
                        padding: "1px 6px",
                        borderRadius: "10px",
                      }}
                    >
                      Approved
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      {getNumberFromString(today?.video_stats?.Approved)}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "min-content",
                        background: "#f9e5e5",
                        color: "#d75656",
                        fontSize: ".6rem",
                        padding: "2px 8px",
                        borderRadius: "10px",
                      }}
                    >
                      Rejected
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      {getNumberFromString(today?.video_stats?.Rejected)}
                    </div>
                  </div>
                  <div className={styles.infoIcon}>
                    <img src={Videocaptured} />
                  </div>
                </InfoCard>
                <InfoCard>
                  <div
                    style={{
                      height: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "2px",
                        textAlign: "center",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      New <br />
                      Videos
                    </p>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      {today?.video}
                    </div>
                  </div>
                  <div className={styles.infoIcon}>
                    <img src={Videostats} />
                  </div>
                </InfoCard>
              </div>
            </div>
            {IS_ADMIN && <img src={Line} />}
            {IS_ADMIN && (
              <div style={{ width: "50%" }}>
                <div className="text-center">
                  <div
                    style={{
                      fontSize: "5.2rem",
                      fontWeight: "bold",
                      margin: ".9rem 0 1.5rem 0",
                    }}
                  >
                    TOTAL
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                    justifyContent: "center",
                  }}
                >
                  <InfoContainer>
                    <div
                      style={{
                        height: "90%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "2px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Total <br /> Contractors
                      </p>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          textAlign: "center",
                        }}
                      >
                        {getNumberFromString(total?.contractors)}
                      </div>
                    </div>
                    <div className={styles.infocardIcon}>
                      <img src={Detective} />
                    </div>
                  </InfoContainer>
                  <InfoContainer>
                    <div
                      style={{
                        height: "95%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "2px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Total <br /> Video Stats
                      </p>
                      <div
                        style={{
                          textAlign: "center",
                          width: "min-content",
                          background: "#353535",
                          color: "#cdcdcd",
                          fontSize: ".6rem",
                          padding: "1px 6px",
                          borderRadius: "10px",
                        }}
                      >
                        Approved
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                          color: "#4caf50",
                        }}
                      >
                        {getNumberFromString(total?.video_stats?.Approved)}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          width: "min-content",
                          background: "#353535",
                          color: "#cdcdcd",
                          fontSize: ".6rem",
                          padding: "2px 8px",
                          borderRadius: "10px",
                        }}
                      >
                        Rejected
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                          color: "#c20000",
                        }}
                      >
                        {total?.video_stats?.Rejected}
                      </div>
                    </div>
                    <div className={styles.infocardIcon}>
                      <img src={Videocaptured} />
                    </div>
                  </InfoContainer>
                  <InfoContainer>
                    <div
                      style={{
                        height: "90%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "2px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Total <br />
                        Videos
                      </p>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          textAlign: "center",
                        }}
                      >
                        {getNumberFromString(total?.video)}
                      </div>
                    </div>
                    <div className={styles.infocardIcon}>
                      <img src={Videostats} />
                    </div>
                  </InfoContainer>
                </div>
              </div>
            )}
          </Box>
        </div>
      )}
    </>
  );
};

export default Dashboard;

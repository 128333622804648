import { useState, useContext, useEffect } from "react";
import styles from "./video-list.module.scss";
import avatar from "assets/images/default.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import NoVideo from "assets/images/video-placeholder.svg";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";

import moment from "moment";
import VideoCard from "app/freelancer-info/video-card/video-card";
import { Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import GalleryVideoPlayer from "../gallery-video-player/gallery-video-player";
import { simulatePostApi } from "app/downloadfn";

const UserVideoList = ({ data, getData, refresh }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const videoID = queryParams.get("video");
  const UserName = queryParams.get("name");
  const selectedVideo = data?.videos?.filter((item) => item?.id === videoID);
  const { name } = selectedVideo?.[0] || {};
  const {
    dispatch,
    state: { userId },
  } = useContext(GlobalContext);

  const [open, setOpen] = useState(false);
  const [freelancerDetail, setFreelancerDetail] = useState();
  const [videoStatus, setVideoStatus] = useState();

  const [opensort, setOpenSort] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [statusList, setStatus] = useState([]);

  const [videoCopy, setVideoCopy] = useState(data);
  const [yesterdayVideoCopy, setYesterdayVideoCopy] = useState([]);

  const [openPrompt, setOpenPrompt] = useState(false);

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const [unselectAll, setUnSelectAll] = useState(false);

  const [totalDeletedVideos, setTotalDeletedVideos] = useState(0);

  const [profileImg, setProfileImg] = useState();

  const [videoLists, setVideoLists] = useState({});

  const [selectedVideoURL, setSelectedVideoURL] = useState("");

  const handleClose = (event, reason, showPrompt) => {
    setOpenSort(null);
    setOpen(false);
    showPrompt && setOpenPrompt(true);
  };
  // useEffect(() => {
  //     onChangeTimeSort()
  // },[])
  // useEffect(() => {
  //   !open && getFreelancerDetails();
  // }, [open]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const getFreelancerDetails = async () => {
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const { profile_details, video_stats, video, status } = userId.admin
        ? await AuthServices.getAdminDetail(userId.id)
        : await AuthServices.getFreelancerDetails(userId.id);

      const profInfo = { ...profile_details, video };
      profInfo.video = profInfo?.video?.map((item, i) => ({
        ...item,
        date: `${new Date(item.date).toDateString()}  ${new Date(
          item.date
        ).getHours()}:${new Date(item.date).getMinutes()}`,
      }));
      setFreelancerDetail(profInfo);
      setVideoStatus(video_stats);
      setIsActive(!!profile_details?.status);
      storeHandler(actions.SHOW_LOADER, false);
      if (profInfo?.video?.length) {
        const todayVideo = profInfo.video
          .filter((item) => item.today)
          .sort((a, b) => new Date(b.date) - new Date(a.date));
        const yesterdayVideo = profInfo.video
          .filter((item) => !item.today)
          .sort((a, b) => new Date(b.date) - new Date(a.date));
        catagorizeVideo(yesterdayVideo);
        setVideoCopy([...todayVideo]);
        setYesterdayVideoCopy([...yesterdayVideo]);
      }
      if (status) {
        const statusArray = Object.keys(status);
        const statusArraySort = statusArray
          .sort((a, b) => moment(a) - moment(b))
          .reverse(); // Date Sort by date order and reverse the date
        const statusValue = statusArraySort.map((item) => ({
          date: moment
            .utc(new Date(item), "MM/DD/YYYY")
            .local()
            .format("MM/DD/YYYY"),
          value: status[item],
        })); //Object changed utc from local time
        const filterSameDate = statusValue.map((oldDate) => oldDate.date); // Date mapped for filter function
        const filteredDate = statusValue.filter(
          ({ date }, index) => !filterSameDate.includes(date, index + 1)
        ); //Finally Removed duplicate data by date
        setStatus([...filteredDate]);
      }
      if (!!profInfo?.profile) {
        setProfileImg(profInfo.profile);
        // getBase64Image(profInfo.profile, setProfileImg);
      }
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const catagorizeVideo = (yesterdayVideo) => {
    const videoList = {};
    yesterdayVideo.forEach((item) => {
      const { date } = item;

      let key = date ? new Date(date).toLocaleDateString() : null;
      if (key) {
        if (videoList[key]) {
          videoList[key].push(item);
        } else {
          videoList[key] = [item];
        }
      }
    });

    setVideoLists({ ...videoList });
  };
  const deleteVideos = async (url) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { id } = userId;
      // await AuthServices.deleteVideo(
      //   { urls: url ? [url] : [...selectedVideos] },
      //   id
      // );
      storeHandler(actions.SHOW_LOADER, false);
      // setOpenDeletePrompt(true);
      refresh();
      // setUnSelectAll(true);
      // setSelectAll(false);
      // setTotalDeletedVideos(url ? 1 : selectedVideos?.length);
      // setSelectedVideos([]);
      // !url && udpateBulkSelect();
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const udpateBulkSelect = () => {
    setIsBulkSelect(!isBulkSelect);
    if (isBulkSelect) {
      // if bulk select is switched off. since setstate will take time to update the value assuming isBulkselect as switching off
      clearSelection();
      setSelectAll(false);
    }
  };
  const clearSelection = () => {
    setSelectedVideos([]);
    setUnSelectAll(true);
  };

  const generateVideoUrl = async () => {

    if(!selectedVideo){
      console.log(selectedVideo?.[0], "selectedVideo?.[0] is empty")
      return
    }
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const res_url = await simulatePostApi(selectedVideo?.[0]);
      setSelectedVideoURL(res_url?.[0]);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  useEffect(() => {
    if (videoID) {
      generateVideoUrl();
    }
  }, [videoID]);

  return (
    <div className="pt-0" style={{ width: "100%", display: "flex" }}>
      <div className="d-flex flex-wrap">
        <div className={`flex-grow-1 ${styles.infoWrap}`}>
          <Stack
            direction="row"
            gap={"1rem"}
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() =>
              getData([
                { key: "name", value: data?.user_name },
                { key: "user_id", value: data?.user_id },
              ])
            }
          >
            <img
              src={data?.profile || avatar}
              width="60px"
              height="60"
              style={{ borderRadius: "50%", border: "1px solid black" }}
              alt="user"
            />
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {data?.user_name}
            </Typography>
            {!UserName && (
              <ArrowForwardIosIcon style={{ marginLeft: "1rem" }} />
            )}
          </Stack>
          <div className="d-flex flex-wrap">
            {!data?.videos?.length && (
              <div className="text-center flex-grow-1">
                <img
                  src={NoVideo}
                  alt="no video"
                  className={`${styles.noVideo}`}
                />
                <p className="txt-lg txt-primary my-0">
                  Oops There is no Recorded Video
                </p>
              </div>
            )}
            <div className="position-relative">
              {!!data?.videos?.length && (
                <div className={`${styles.videoSectionBody}`}>
                  <div
                    className={`${styles.videoSection} py-4 d-flex justify-content-between flex-grow-1 flex-wrap p-0`}
                  >
                    <div
                      className={`${styles.videoCardSection} d-flex flex-wrap justify-content-lg-start justify-content-center px-0`}
                    >
                      {data?.videos?.map((item, i) => (
                        <div className="pr-5 mb-5">
                          <div
                            className={`${
                              videoID == item?.id && styles.videoSelected
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              getData([
                                { key: "name", value: data?.user_name },
                                { key: "user_id", value: data?.user_id },
                                { key: "video", value: item?.id },
                              ]);
                            }}
                          >
                            <VideoCard
                              deleteVideos={deleteVideos}
                              freelancerDetail={freelancerDetail}
                              unselectAll={unselectAll}
                              setUnSelectAll={setUnSelectAll}
                              info={item}
                              selectedVideos={selectedVideos}
                              setSelectedVideos={setSelectedVideos}
                              selectAll={selectAll}
                              index={i}
                              isBulkSelect={isBulkSelect}
                              getFreelancerDetails={refresh}
                              showPlayer={false}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {videoID && selectedVideoURL && (
        <div
          className="pr-3"
          style={{
            width: "min-content",
            marginTop: "60px",
            marginLeft: "auto",
          }}
        >
          <div style={{ position: "relative" }} className="pt-4">
            <Typography
              variant="h5"
              style={{ position: "absolute", top: "-.6rem" }}
            >
              {name}
            </Typography>
            <GalleryVideoPlayer
              data={selectedVideo?.[0] || {}}
              video={selectedVideoURL}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserVideoList;

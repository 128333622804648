import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./OuterCommentStyle.module.scss";

import { Button, Snackbar, TextField } from "components";
import { InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { convertSecondsToHMS } from "utils/videoUtils";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: ".5rem",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: ".5rem",
    },
  },
}));

const sortArrayByProperty = (array, key) => {
  if (!Array.isArray(array)) {
    console.error("First argument must be an array.");
    return [];
  }

  if (typeof key !== "string" || key.trim() === "") {
    console.error("Key must be a non-empty string.");
    return [];
  }

  if (array.length > 0 && typeof array[0] !== "object") {
    console.error("Array must contain objects.");
    return [];
  }

  return array.slice().sort((a, b) => {
    const valueA = Number(a[key]);
    const valueB = Number(b[key]);

    const validValueA = isNaN(valueA) ? 0 : valueA;
    const validValueB = isNaN(valueB) ? 0 : valueB;

    return validValueA - validValueB;
  });
};

const roundValue = (time) => {
  const validTime = Number(time);
  if (!isNaN(validTime) && validTime >= 0) {
    const roundedTime = Math.round(validTime);
    return roundedTime;
  } else {
    console.warn("Invalid time value provided:", time);
  }
};

function OuterComment({
  approval,
  id,
  comments,
  videRef,
  handleSetComment,
  isVideoPause,
  name,
  getAllComment
}) {
  const { dispatch } = useContext(GlobalContext);
  const classes = useStyles();
  const playerRef = useRef(null);
  const [showTimeBtn, setShowTimeBtn] = useState(false);
  const [videoApprove, setVideoApprove] = useState(approval);
  const [commentList, setCommentList] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleApproveRejectVide = async (status) => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      await AuthServices.changeVideoStatus({ id, status });
      setVideoApprove(status);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleAddComment = async () => {
    const currentTime = videRef?.current?.currentTime();
    const isDuplicate = comments.some(
      (commentObj) => Number(commentObj.time_sec) === roundValue(currentTime)
    );
    if (comment.length === 0) {
      setErrorMessage("Comment cannot be empty.");
      return;
    } else if (comment.length > 300) {
      setErrorMessage("Comment cannot exceed 300 characters.");
      return;
    } else if (isDuplicate) {
      setErrorMessage(
        "A comment already exists at this time. Please choose a different time."
      );
      return;
    } else {
      setErrorMessage("");
    }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const payload = {
        video_id: id,
        time_sec: roundValue(currentTime),
        comment,
      };
      await AuthServices.addVideoComment(payload);
      // const newCommentList = [payload, ...comments];
      // const sortedList = sortArrayByProperty(newCommentList, "time_sec");
      // handleSetComment(sortedList);
      setComment("");
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment()
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const seekToTime = (time) => {
    if (videRef.current) {
      videRef?.current?.currentTime(time);
      videRef?.current?.pause();
      console.log(`Seeked to ${time} seconds`);
    }
  };

  //   useEffect(() => {
  //     setShowTimeBtn(false);
  //     setComment("");
  //     setErrorMessage("");
  //     handleGetComments();
  //   }, []);

  //   useEffect(() => {
  //     setVideoApprove(approval);
  //   }, [approval]);

  return (
    <div className={`${styles.commentBody}`}>
      <div className="p-1">
        <TextField
          placeholder="Add Comment.."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onFocus={() => videRef?.current?.pause()}
          classes={{ root: classes.root }}
          variant="outlined"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: "#ededed",
            },
            startAdornment: (
              <InputAdornment position="start">
                {isVideoPause && (
                  <Button
                    variant="contained"
                    disableRipple={true}
                    style={{
                      padding: ".3rem",
                      fontSize: ".8rem",
                      fontWeight: "200",
                      backgroundColor: "white",
                      color: "black",
                      boxShadow: "none",
                      cursor: "not-allowed",
                    }}
                  >
                    {convertSecondsToHMS(
                      videRef?.current?.currentTime(),
                      false
                    )}
                  </Button>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={!isVideoPause}
                  onClick={handleAddComment}
                  variant="contained"
                  disableRipple={true}
                  style={{
                    padding: ".3rem",
                    fontSize: ".8rem",
                    fontWeight: "200",
                    backgroundColor: "black",
                    color: "white",
                    boxShadow: "none",
                  }}
                >
                  Post
                </Button>
              </InputAdornment>
            ),
          }}
          helperText={errorMessage}
        />
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={"1rem"}
        className=" my-3"
      >
        {(videoApprove === true || videoApprove === null) && (
          <Button
            disabled={videoApprove === true}
            onClick={(e) => {
              handleApproveRejectVide(true);
            }}
            style={{
              padding: ".3rem 1rem",
              borderRadius: "2rem",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: "black",
              color: "white",
              boxShadow: "none",
              width: "5rem",
            }}
          >
            {videoApprove === true ? "Approved" : "Approve"}
          </Button>
        )}

        {(videoApprove === false || videoApprove === null) && (
          <Button
            disabled={videoApprove === false}
            variant="contained"
            onClick={() => handleApproveRejectVide(false)}
            style={{
              padding: ".3rem 1rem",
              width: "5rem",
              borderRadius: "2rem",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: "var(--error-main)",
              color: "white",
              boxShadow: "none",
            }}
          >
            {videoApprove === false ? "Rejected" : "Reject"}
          </Button>
        )}
      </Stack>

      <Stack
        direction="column"
        maxHeight={"15rem"}
        style={{ overflowY: "scroll" }}
      >
        {!!comments?.length && (
          <Stack className={`${styles.allComment}`}>
            {comments?.map(({ id, comment, time_sec }) => (
              <Stack
                className={`${styles.comment} ${roundValue(videRef?.current?.currentTime()) === time_sec && styles.active }`}
                key={id}
                onClick={() => seekToTime(time_sec)}
              >
                <Typography variant="caption">
                  {convertSecondsToHMS(time_sec, false)}
                </Typography>
                <Typography variant="caption">{comment}</Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </div>
  );
}

export default OuterComment;

import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./Gallery.module.scss";
import { Paper, Typography } from "@material-ui/core";
import { actions, GlobalContext } from "context";
import { InputField, Snackbar } from "components";
import AuthServices from "api/services/auth-services";
import { Stack } from "@mui/material";
import ButtonWithIcon from "components/IconButton/icon-button";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import YearCard from "./year-card/year-card";
import { useHistory, useLocation } from "react-router-dom";
import UserVideoList from "./video-detail/video-list";
import Breadcrumb from "./breadcrumb/breadcrumb";

function addKeyValueToNestedObjects(obj, key, value) {
  if (typeof obj !== "object" || obj === null) {
    throw new TypeError("The first argument must be a non-null object.");
  }

  if (typeof key !== "string" || key.trim() === "") {
    throw new TypeError("The key must be a non-empty string.");
  }

  if (value === undefined) {
    throw new TypeError("The value must be defined.");
  }

  const newObj = {};

  for (const year in obj) {
    if (obj.hasOwnProperty(year)) {
      if (typeof obj[year] === "object" && obj[year] !== null) {
        newObj[year] = { ...obj[year], [key]: value };
      } else {
        console.warn(`Skipping ${year}: Not an object.`);
        newObj[year] = obj[year];
      }
    }
  }

  return newObj;
}

const monthNameToNumber = (monthName) => {
  const monthMap = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
  };

  const monthKey = monthName?.toLowerCase();

  return monthMap[monthKey] || null;
};

function Gallery() {
  const {
    dispatch,
    state: { showLoader },
  } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const [showSnackBar, setShowSnackBar] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const month = queryParams.get("month");
  const day = queryParams.get("day");
  const name = queryParams.get("name");
  const user_id = queryParams.get("user_id");

  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const [galleryCopy, setGalleryCopy] = useState([]);
  const [userCopy, setUserCopy] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllVideo = async (value) => {
    storeHandler(actions.SHOW_LOADER, true);

    const determineFetchData = () => {
      const monthNameInNum = monthNameToNumber(month);
      if (year) {
        if (month) {
          if (day) {
            if (name) {
              return AuthServices.getUserGalleryVideo(
                year,
                monthNameInNum,
                day,
                user_id
              );
            }
            return AuthServices.getGalleryDay(year, monthNameInNum, day);
          }
          return AuthServices.getGalleryDays(year, monthNameInNum);
        }
        return AuthServices.getGalleryMonth(year);
      }
      return AuthServices.getGalleryYear();
    };

    try {
      const fetchData = determineFetchData();

      const data = await fetchData;
      if (day || name) {
        setUserCopy(data?.data);
        setGalleryCopy([]);
      } else {
        const updatedData = addKeyValueToNestedObjects(data, "type", value);
        const desiredOrder = Object.keys(updatedData);
        desiredOrder?.sort((a, b) => parseInt(a) - parseInt(b));
        const sortedArray = desiredOrder?.map((key) => ({
          key,
          ...updatedData[key],
        }));

        setGalleryCopy(sortedArray || []);
        setUserCopy([]);
      }

      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const handleNavigate = (params) => {
    const query = new URLSearchParams(location.search);

    params.forEach(({ key, value }) => {
      query.set(key, value);
    });

    history.push({ search: query.toString() });
  };

  const handleGetGalleryVideo = () => {
    if (year && !month && !day) {
      getAllVideo("month");
    } else if (year && month && !day) {
      getAllVideo("day");
    } else if (year && month && day && !name) {
      getAllVideo();
    } else if (year && month && day && name) {
      getAllVideo();
    } else {
      getAllVideo("year");
    }
  };

  useEffect(() => {
    handleGetGalleryVideo();
  }, [year, month, day, name]);


  const filteredVideos = useMemo(() => {
    try {
      if (!searchTerm.trim()) {
        return userCopy;
      }

      const filteredUsers = userCopy.filter((user) =>
        user.videos.some((video) =>
          video.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      return filteredUsers.map((user) => ({
        ...user,
        videos: user.videos.filter((video) =>
          video.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      }));
    } catch (err) {
      console.log("An error occurred while filtering the data.");
      return [];
    }
  }, [searchTerm, userCopy]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.GalleryHeader} d-flex justify-content-between align-items-center flex-md-row flex-column `}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Gallery
        </Typography>
      </Paper>
      {/* <button onClick={() => history.push("/video")}>Video</button> */}
      <Stack
        direction="row"
        className="pt-4 pb-3"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap={"3rem"} alignItems="center">
          {(!!galleryCopy?.length || !!filteredVideos?.length) && (
            <Breadcrumb />
          )}
          {queryParams.get("day") && (
            <InputField
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              variant="filled"
              placeholder="Search by video name"
              withAdronment={true}
            />
          )}
        </Stack>

        {false && queryParams.get("day") && (
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            <ButtonWithIcon disableRipple startIcon={<FilterIcon />}>
              Filter
            </ButtonWithIcon>

            <ButtonWithIcon disableRipple startIcon={<SortIcon />}>
              Sort
            </ButtonWithIcon>
          </Stack>
        )}
      </Stack>

      <div
        className={`${styles.galleryCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
      >
        <>
          {galleryCopy?.map((item) => (
            <div className="px-3 my-0">
              <YearCard key={item?.key} data={item} getData={handleNavigate} />
            </div>
          ))}

          {queryParams.get("day") &&
            filteredVideos?.map((item) => (
              <UserVideoList
                data={item}
                getData={handleNavigate}
                refresh={handleGetGalleryVideo}
              />
            ))}
        </>

        {!showLoader && !galleryCopy?.length && !filteredVideos?.length && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            There are No Data!
          </p>
        )}
      </div>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </div>
  );
}

export default Gallery;
